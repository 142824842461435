﻿//Required:
//    columns : { label: '', odataName: '', transform: function (rowData) { returns cell value }, id: false, nosort: false, visible: true, html: false, classes: [], contents: [{}] }
//    dataUrl : string

//Optional:
//    oDataFilters: string, OData filters that will always be applied to data queries, in addition to any set by ChangeFilter.
//    maxHeight: int (overrides maxHeightFunction)
//    maxHeightFunction : int function()
//    minHeightFunction: int function(), defaults to a function that returns the height of the first row in the table. This function
//                                       should return the desired height of the area for rows; the header row will be accounted for.
//    sortable: true
//    additionalApiParams : {}
//    rowDataTransform : function (data) (is run on the data before pushing it into the table array)
//    defaultSort : string (now allows default sorting by 2 columns)
//    cutoffCol : string or datetime (See cutoffColType if datetime)
//                        (If this is set, then after the first set of rows is brought back, the value in this col of the first row is set as a filter. 
//                        It will only return rows with a value <= that value in the specified column. Good for auto-incrementing ids in busy tables to
//                        prevent rows from duplicating on paging due to having more rows to skip than when paging was set.
//                        If used, the default sort should be set to this column descending to ensure that the proper cutoff value is grabbed.)
//    cutoffColType : "string" || "datetime". The type of data contained in the cutoffCol, so the proper OData query can be made. Defaults to string.
//    loadingMessage : Is displayed while loading rows. A loading wheel is automatically loaded. Default: "Loading..."
//    errorMessage : Is displayed when an error occurs. A retry link is automatically inserted. Default: 'Unable to load rows.'
//    noDataMessage : Is displayed when no rows are returned. Default: "No records are available."
//    nohover : Turns off row highlighting
//    refreshTime : Enables an auto refresh that happens every X miliseconds
//    highlightFunction : Highlights rows specified by function.  Function must pass back a Class that contains a tr background change.
//    highlightDirty : highlights dirty rows
//    viewModelExtension : additional functions or properties to add to the viewModel.
//    recordsPerPage : How many records are requested from the server at a time. Defaults to 30

//Columns:
//label - What to display in the table header.
//odataName - The name used in odata queries to the back end. Can be blank if a transform is present to allow for calculated fields.
//transform - A function that takes the row's data and returns a value for the cell
//id - Adds an id class to the element. Default false.
//nosort - Disables sort for this column when true. Defaults false.
//helpText - Adds a help icon that displays on hover to this cell. Help text is given by passing the odata name of the field containing the string.
//html - Should be true if the value for the cell is html to prevent escaping of characters. Default false.
//visible - Defaults to true if the label is set, false otherwise.
//classes - An array of classes to set on the element
//contents - Used to define contents for a cell such as checkboxes or links.
//sortType - Used to sort data how it's expected. Values: 'number' or 'string', default 'string'
//alignment - Set the alignment of values in the cells. Values: 'left', 'right', 'center', default 'left'
//wrapText - Allows the text in a column to wrap. Default false.

//Contents:
//text - The text contents of the element.
//type - Sets the type of the content. Current options: checkbox, radio or link.
//databind - Sets the databinding on the element.
//classes - An array of classes to set on the element
//value - the value of the element
//checked - the checked databind for the element (so it can be wrapped to parse odata stuff)

//NOTE: Radio buttons do not currently support attr databindings, since they are used for the name groups.

//Bind points:
//loading (e)- when the table begins loading
//success (e)- the table successfully loaded rows
//error (e, getjson result) - the table failed to load rows
//rowClicked (e, jquery row object) - a row in the table was clicked
//reset (e) - the table was reset; the table is currently empty

//Functions:
//Usage: $(tableElement).koDataGrid('methodName', params);
//
//changeFilter(filter) - Takes an array of conditions. Changes the filter on the table to the passed filter and reloads the table.
//reset() - Resets the table to it's original display.
//updateSize() - Forces an update of the table height. Should be called if something effecting the table's heightFunction is changed.
//addRow(rowData) - Adds the passed row to the view model.
//resetChanges() - Resets uncommited changes
//commitChanges() - Sets the dirty flag on all rows to false. DOES NOT SAVE THE DATA ITSSELF.
//getDirtyRows() - Gets all rows marked as dirty.
//refresh() - Calls the refresh method on any elements in the data grid that need it. Currently used on multiselect.
//setRowDataProperty(id, name, value) - Finds the row with the given id, then sets the named property to the passed value.

(function ($) {
   $.widget('ui.koDataGrid', {
      changeFilter: function (filterArray) {
         if (this.options.oDataFilters !== null)
            filterArray.push(this.options.oDataFilters);
         this.options.currentFilter = filterArray.join(' and ');
         this._resetTable(true);
         this._clearSort();
         this._load();
      },

      reset: function () {
         this.changeFilter('');
      },

      updateSize: function () {
         var widget = this;
         if (widget.options.maxHeightFunction) {
            widget.element.find(widget._options.scrollWrapper).css('max-height', widget.options.maxHeightFunction());
         }
         if (widget.options.minHeightFunction) {
            widget.element.css('min-height', widget.options.minHeightFunction());
         }
         $.proxy(widget._resizeGrid, widget)();
         Softek.ensureElementVisible(widget.element.find(widget._options.scrollWrapper), widget._options.selectedRow);
      },

      refresh: function () {
         var widget = this;
         //Doing a dull destroy-rebind because I haven't been able to get the enabled 
         //status to update using any of the built in functions.
         widget.element.find('.koDataGrid_select').multiselect('destroy');
         widget.element.find('.koDataGrid_select').multiselect(widget._buildMultiSelectOptions());
         widget.updateSize();
      },

      _intervalRefresh: function (time) {
         var widget = this;
         setTimeout(intervalRefresh, time);
         function intervalRefresh() {
            widget._resetTable(true);
            widget._load();
            setTimeout(intervalRefresh, time);
         }

      },

      addRow: function(row) {
         this._addRow(row);
         this.updateSize();
         this._setColumnWidths();
      },

      removeRowById: function (id) {
         this.options.viewModel.koDataGrid_dataRows.remove(
            ko.dataFor(this.element.find('#' + id).get(0)));
         this._setColumnWidths();
      },

      setRowDataProperty: function (id, name, value) {
         var knockoutObject = ko.dataFor(this.element.find('#' + id).get(0));
         name.split('.').forEach(function(currentValue, i, array) {
            knockoutObject = knockoutObject[currentValue];
         }); 
         knockoutObject(value);
         this.refresh();
      },

      getRowById: function (id) {
         return ko.mapping.toJS(ko.dataFor(this.element.find('#' + id).get(0)));
      },

      selectRowById: function (id) {
         this.element.find('#' + id).click();
      },

      resetChanges: function () {
         $.each(this.options.viewModel.koDataGrid_dirtyRows(), function (i, row) {
            row.koDataGrid_dirtyFlag.undo();
         });
      },

      commitChanges: function () {
         $.each(this.options.viewModel.koDataGrid_dirtyRows(), function (i, row) {
            row.koDataGrid_dirtyFlag.commit();
         });
      },

      getDirtyRows: function () {
         var dirtyRows = [];
         $.each(this.options.viewModel.koDataGrid_dirtyRows(), function (i, row) {
            dirtyRows.push(ko.mapping.toJS(row));
         });
         return dirtyRows;
      },

      options: {
         currentPage: 0,
         recordsPerPage: 30,
         currentFilter: "",
         oDataFilters: null,
         done: false,
         isFirstLoad: true,
         columnSizeSet: false,
         rowTransform: function (data) {
         },
         maxHeightFunction: undefined,
         minHeightFunction: function() {
            var widget = this;
            var element = widget.element;
            if (!element)
               return 0;
            var rows = element.find('#koDataGrid_body tbody tr');
            return rows ? Math.min(rows.height(), 54) : 0;
         },
         defaultSortAsc: false,
         highlightDirty: false,
         highlightFunction: undefined,
         sortAsc: false,
         cutoffSet: false,
         cutoffColType: "string",
         nohover: false,
         sortable: true,
         loadingMessage: "Loading...",
         errorMessage: 'Unable to load rows.',
         noDataMessage: "No records are available."
      },

      _create: function () {
         var widget = this;
         widget.element.addClass(widget._options.rootClass);

         var settings = widget.options;

         settings.columns = widget._expandColumns(settings.columns);
         settings.viewModel = new widget._gridViewModel(settings.columns, settings.viewModelExtension);
         widget._buildTable(widget.element, settings.columns, settings);

         if (settings.defaultSort)
            widget._setDefaultSort(settings.defaultSort);
         else
            widget._setDefaultSort(settings.id + ' ' + "desc");

         if (settings.rowDataTransform)
            settings.rowTransform = settings.rowDataTransform;
         
         widget.element.find(widget._options.scrollWrapper).scroll($.proxy(widget._scrollGrid, widget));
         $(window).scroll($.proxy(widget._scrollGrid, widget));

         if (settings.sortable)
            widget.element.find("thead").on('click', 'tr th:not(.nosort)', function () {
               widget._clearSortClasses();

               if (widget.options.sortCol[0] != $(this).attr("odataName")) {
                  widget.options.sortCol = [];
                  widget.options.sortAsc = [];
                  widget.options.sortCol.push($(this).attr("odataName"));
                  widget.options.sortAsc.push(false);
               } else {
                  widget.options.sortAsc[0] ^= true;
               }

               var sortArrow = "<span class='" + widget._currentSortClass(widget.options.sortAsc[0]) + "'/>";
               if ($(this).css('text-align') == 'right') {
                  $(this).prepend(sortArrow);
               } else {
                  $(this).append(sortArrow);
               }

               widget._resetTable(false);
               widget._load();
            });
         else
            widget.element.find("thead tr th").addClass(widget._options.nohoverClass);

         if (settings.maxHeight)
            widget.element.find(widget._options.scrollWrapper).css('max-height', settings.maxHeight);
         else if (settings.maxHeightFunction)
            widget._initMaxHeightFunction(settings.maxHeightFunction);

         if (settings.minHeightFunction)
            widget._initMinHeightFunction(settings.minHeightFunction);

         if (settings.cutoffCol)
            widget.options.cutoffCol = settings.cutoffCol;

         if (settings.cutoffColType)
            widget.options.cutoffColType = settings.cutoffColType;

         if (settings.oDataFilters)
            widget.options.currentFilter = settings.oDataFilters;

         if (settings.refreshTime)
            widget._intervalRefresh(settings.refreshTime); 

         $(document).keydown($.proxy(widget._handleKeyPress, this));

         widget.element.find('tbody').on('click', 'tr:not(' + widget._options.loadingRow + ', ' + widget._options.errorRow + ')', function () {
            widget.element.find(widget._options.selectedRow).removeClass(widget._options.selectedClass);

            $(this).addClass(widget._options.selectedClass);

            widget._trigger(widget._options.rowClickedEvent, {}, this);
         });

         $(window).resize($.proxy(widget._resizeGrid, widget));

         ko.applyBindings(widget.options.viewModel, widget.element.get(0));

         widget._resetTable(true);
         widget._load();
      },

      _gridViewModel: function (columns, extensions) {
         var viewModel = this;
         viewModel.koDataGrid_columns = ko.observableArray(columns);
         viewModel.koDataGrid_dataRows = ko.observableArray([]);

         viewModel.koDataGrid_loadingData = ko.observable(false);
         viewModel.koDataGrid_loadingError = ko.observable(false);

         viewModel.koDataGrid_dirtyRows = ko.dependentObservable(function () {
            return ko.utils.arrayFilter(viewModel.koDataGrid_dataRows(), function (row) {
               return row.koDataGrid_dirtyFlag.isDirty();
            });
         });

         viewModel.unwrapData = function (data) {
            return ko.mapping.toJS(data);
         };

         viewModel.getODataValue = function (row, odataName) {
            var propertyNames = odataName.split("/");

            var value = row;
            $.each(propertyNames, function (i, propertyName) {
               if (value === undefined)
                  return "";
               value = value[propertyName];
            });
            return value;
         };

         $.extend(viewModel, extensions);
      },

      _expandColumns: function (configColumns) {
         var columns = [];
         $.each(configColumns, function (i, column) {
            columns[i] = $.extend({
               id: false,
               html: false,
               visible: Boolean(column.label),
               type: 'text',
               sortType: 'string',
               lineBreak: false,
               wrapText: false
            }, column);
         });
         return columns;
      },

      _setDefaultSort: function (defaultSort) {
         var widget = this;
         var sort = defaultSort.split(", ");
         var listColums = [];
         var listOrders = [];
         $.each(sort, function(i, ele) {
            var secondSort = ele.split(" ");
            listColums.push(secondSort[0]);
            listOrders.push(secondSort[1] ? secondSort[1].toUpperCase() == "ASC" : false);
         });

         widget.options.sortCol = widget.options.defaultSortCol = listColums;
         widget.options.sortAsc = widget.options.defaultSortAsc = listOrders;
      },

      _resizeGrid: function () {
         var widget = this;
         widget._loadRowsIfSpace();
         widget._setColumnWidths();
         widget._positionOpenMultiSelectDropdown();

         //Delay a call to IE8 hacks until any events that called this function are done
         setTimeout($.proxy(widget._IE8Hacks, widget), 1);
      },

      _scrollGrid: function () {
         var widget = this;
         widget._loadRowsIfSpace();
         widget._positionOpenMultiSelectDropdown();
      },

      //IE8 has an issue that when a horizontal scroll bar appears, max-height is treated as the height.
      //A workaround is to set the height property, so we're calculating that out by hand.
      //Must be called after other resize logic, as it pulls out the min and max values from the elements.
      //Only runs if the page detected IE8.
      _IE8Hacks : function() {
         var widget = this;
         if (!$('html.lt-ie9').length)
            return;
         var scrollWrapper = widget.element.find(widget._options.scrollWrapper);
         var tableBody = widget.element.find('#' + widget._options.tableBody);
         var minHeight = parseInt(widget.element.css('min-height').replace('px', '')) - $(widget._options.headWrapper).height();
         var maxHeight = parseInt(scrollWrapper.css('max-height').replace('px', ''));
         var tableHeight = tableBody.outerHeight(true);
         var tableWidth = tableBody.outerWidth(true);
         var scrollWrapperWidth = scrollWrapper.width();
         var scrollWrapperHeight;
         if (tableHeight < minHeight)
            scrollWrapperHeight = minHeight;
         else if (tableHeight > maxHeight)
            scrollWrapperHeight = maxHeight;
         else
            scrollWrapperHeight = tableHeight;

         if (scrollWrapperWidth < tableWidth)
            scrollWrapperHeight += Softek.getScrollBarWidth();

         widget.element.find(widget._options.scrollWrapper).height(scrollWrapperHeight);
      },

      _loadRowsIfSpace: function () {
         var widget = this;
         if (!widget.options.isFirstLoad && widget._isEmptySpaceInTable()) {
            widget._load();
         }
      },

      _isEmptySpaceInTable: function () {
         var widget = this;
         return widget.element.find(widget._options.scrollWrapper)[0].scrollHeight - widget.element.find(widget._options.scrollWrapper).scrollTop() <=
            widget.element.find(widget._options.scrollWrapper).outerHeight() && !widget.options.viewModel.koDataGrid_loadingData() && !widget.options.viewModel.koDataGrid_loadingError();
      },

      _initMaxHeightFunction: function (maxHeightFunction) {
         var widget = this;
         widget.options.maxHeightFunction = widget._createHeightFunction(maxHeightFunction);
         widget.element.find(widget._options.scrollWrapper).css('max-height', widget.options.maxHeightFunction());
         Softek.ensureElementVisible(widget.element.find(widget._options.scrollWrapper), widget._options.selectedRow);
         $(window).resize(function () {
            widget.element.find(widget._options.scrollWrapper).css('max-height', widget.options.maxHeightFunction());
            Softek.ensureElementVisible(widget.element.find(widget._options.scrollWrapper), widget._options.selectedRow);
         });
      },

      _initMinHeightFunction: function (minHeightFunction) {
         var widget = this;
         widget.options.minHeightFunction = widget._createMinHeightFunction(minHeightFunction);
         widget.element.css('min-height', widget.options.minHeightFunction());
         Softek.ensureElementVisible(widget.element.find(widget._options.scrollWrapper), widget._options.selectedRow);
         $(window).resize(function () {
            widget.element.css('min-height', widget.options.minHeightFunction());
            Softek.ensureElementVisible(widget.element.find(widget._options.scrollWrapper), widget._options.selectedRow);
         });
      },

      _createHeightFunction: function (heightFunction) {
         var widget = this;
         return function() {
            var minHeight = widget.options.minHeightFunction();
            return Math.max(minHeight, $.proxy(heightFunction, widget)() - $(widget._options.headWrapper).height());
         };
      },

      _createMinHeightFunction: function (heightFunction) {
         var widget = this;
         return function () { return $.proxy(heightFunction, widget)() + $(widget._options.headWrapper).height(); };
      },

      _resetTable: function (resetCutoff) {
         var widget = this;
         widget.options.cutoffSet = !resetCutoff && widget.options.cutoffSet;
         widget.options.isFirstLoad = true;

         widget.options.currentPage = 0;
         widget.options.done = false;
         widget.options.viewModel.koDataGrid_dataRows([]);
         widget.element.find(widget._options.scrollWrapper).scrollTop(0);

         widget.updateSize();
         widget._trigger(widget._options.resetEvent);
      },

      _clearSort: function () {
         var widget = this;
         widget._clearSortClasses();
         widget.options.sortAsc = widget.options.defaultSortAsc;
         widget.options.sortCol = widget.options.defaultSortCol;
      },

      _clearSortClasses: function () {
         var widget = this;
         widget.element.find(".sortUp, .sortDown").remove();
      },

      _currentSortClass: function (sortDirection) {
         return "sort" + (sortDirection ? "Up" : "Down");
      },

      _handleKeyPress: function (e) {
         var widget = this;
         if (e.keyCode < 37 || e.keyCode > 40)
            return true;

         if (e.target.nodeName == 'INPUT' || e.target.nodeName == 'SELECT')
            return true;

         if (e.keyCode == 37 || e.keyCode == 39) {
            var direction = e.keyCode - 38;
            widget.element.find(widget._options.scrollWrapper)
               .scrollLeft(widget.element.find(widget._options.scrollWrapper).scrollLeft() + (direction * 10));
         } else {
            var selected;
            if (widget.element.find(widget._options.selectedRow).length == 0) {
               selected = widget.element.find('tbody tr:first');
            } else {
               if (e.keyCode == 40) //down arrow
               {
                  selected = widget.element.find(widget._options.selectedRow).next();
                  if (selected.is(widget._options.loadingRow) &&
                     !widget.options.viewModel.koDataGrid_loadingData()) {
                     widget._load();
                     return false;
                  }
               } else { //up arrow
                  selected = widget.element.find(widget._options.selectedRow).prev();
               }
            }
            selected.click();
            Softek.ensureElementVisible(widget.element.find(widget._options.scrollWrapper), selected);
         }
         return false;
      },

      _setColumnWidths: function () {
         var widget = this;
         widget._setMinimumWidth();
         widget._updateColumnHeaderWidths();
         widget._options.columnSizeSet = true;
      },

      _setMinimumWidth: function () {
         var widget = this;
         $.each(widget.options.viewModel.koDataGrid_columns(), function (i, col) {
            $.cssStyle.removeClass([widget._makeCSSClassSelector('column', i), widget._makeCSSClassSelector('header', i)]);
         });
         var minWidth = widget._getColumnMinimumWidth();

         //Set the min width for each of the columns to the header's min width.
         //The headers are updated off the column's widths. widget should prevent narrow data
         //columns from making the headers go off center.
         $.each(minWidth['width'], function (i, width) {
            $.cssStyle.insertRule([widget._makeCSSClassSelector('column', i)], 'min-width: ' + width + 'px;');
            $.cssStyle.insertRule([widget._makeCSSClassSelector('header', i)], 'min-width: ' + width + 'px;');
         });
         var tableWidth = widget.element.find('#koDataGrid_body').width();
         widget.element.find('#koDataGrid_head').css('min-width', tableWidth);
      },

      _makeCSSClassSelector: function (type, index) { return '.' + this._makeCSSClass(type, index); },

      _makeCSSClass: function (type, index) {
         var widget = this;
         return widget.element.attr('id') + '_koDataGrid_' + type + index;
      },

      _getColumnMinimumWidth: function () {
         var widget = this;
         var minWidths = [];
         minWidths['width'] = [];
         widget.element.find('table.results').each(function (i, table) {
            table = $(table);
            table.outerWidth(0);
            table.css('min-width', 0);

            table.find("tbody, thead").find("tr:first").each(function (j, row) {
               $(row).find('td, th').each(function (k, cell) {
                  $(cell).css('width', '0px');
                  var width = $(cell).outerWidth(true);
                  if ($(cell).find('input').length)
                     width += $(cell).find('input').outerWidth(true);

                  if (!minWidths['width'][k] || minWidths['width'][k] < width)
                     minWidths['width'][k] = width;

                  $(cell).css('width', '');
               });
            });

            table.removeAttr('style');
         });
         return minWidths;
      },

      _updateColumnHeaderWidths: function () {
         var widget = this;
         widget.element.find(widget._options.headWrapper).width(
            widget.element.find(widget._options.scrollWrapper).width());
         widget.element.find('#' + widget._options.tableHead).width(
            widget.element.find('#' + widget._options.tableBody).width() + Softek.getScrollBarWidth());
         $.each(widget.element.find("tbody tr:first td:not(:hidden)"), function (index, td) {
            var width = $(td).width();

            var header = $(widget.element.find("thead tr:first th:eq(" + index + ")"));
            if (!header.is(":last-child")) {
               header.width(width);
            }
         });
         widget._syncHeaderScrollWithTable();
      },

      _isSortAscending: function (sortCol, sortAsc) {
         var widget = this;
         var column = $.grep(widget.options.viewModel.koDataGrid_columns(), function (col) { return col.odataName && col.odataName == sortCol; })[0];
         if (column)
            return sortAsc ^ (column.sortType == 'string');
         return sortAsc;
      },

      _isFirstFilter: function(isFirst) {
         if (isFirst) {
            return '$orderby=';
         }
         return ', ';
      },

      _buildFilters: function (columns, orders) {
         var widget = this;
         var firstFilter = true;
         var filterString = "";
         for (var i = 0; i < columns.length; i++) {
            filterString += columns[i] ? widget._isFirstFilter(firstFilter) + columns[i] + ' ' + (widget._isSortAscending(columns[i], orders[i]) ? "asc" : "desc") : '';
            firstFilter = false;
         }
         return filterString;
      },

      _load: function () {
         var widget = this;
         if (widget.options.done)
            return;

         widget._trigger(widget._options.loadingEvent);

         widget.options.viewModel.koDataGrid_loadingError(false);
         widget.options.viewModel.koDataGrid_loadingData(true);

         if (widget.options.currentRequest != null)
            widget.options.currentRequest.abort();

         var skip = widget.options.currentPage * widget.options.recordsPerPage;

         var filters = widget._buildFilters(widget.options.sortCol, widget.options.sortAsc);

         widget.options.currentRequest = $.getJSON(widget.options.dataUrl + '?' +

            filters +
            '&$skip=' + skip +
            '&$top=' + widget.options.recordsPerPage +
            (widget.options.currentFilter ? '&$filter=' + widget.options.currentFilter : ""), widget.options.apiParams,
            function (data) {
               if (data.length != widget.options.recordsPerPage) {
                  widget.options.done = true;
                  if (!data.length)
                     return;
               }

               if (!widget.options.cutoffSet && widget.options.cutoffCol) {
                  var cutoffValue = data[0][widget.options.cutoffCol];
                  if (widget.options.cutoffColType === "datetime")
                     cutoffValue = cutoffValue + "Z";
                  widget.options.currentFilter = (widget.options.currentFilter.length > 0 ? widget.options.currentFilter + " and " : "") + widget.options.cutoffCol + " le " + cutoffValue;
                  widget.options.cutoffSet = true;
               }

               $.each(data, function (i, row) {
                  widget._addRow(row);
               });

               widget.updateSize();
               widget._setColumnWidths();
            })
            .success(function () {
               widget.options.currentRequest = null;
               widget._trigger(widget._options.loadSuccessEvent);
               widget.options.currentPage++;
               widget.options.viewModel.koDataGrid_loadingData(false);

               if (!widget.options.done && widget._isEmptySpaceInTable()) {
                  widget._load();
               }

               if (widget.options.isFirstLoad) {
                  widget.options.isFirstLoad = false;
                  widget._resizeGrid();
                  widget.element.find(widget._options.scrollWrapper).scrollTop(0);
                  $(window).scrollTop(0);
               }
            })
            .error(function (result) {
               widget.options.currentRequest = null;
               if (result.statusText == "abort")
                  return;

               widget.options.viewModel.koDataGrid_loadingData(false);
               widget.options.viewModel.koDataGrid_loadingError(true);

               if (result.status == 401)
                  showUnauthorizedNotification();

               widget._trigger(widget._options.loadErrorEvent, {}, result);
            });
      },

      _addRow: function (data) {
         var widget = this;
         widget.options.rowTransform(data);

         data = ko.mapping.fromJS(data);

         data[widget._options.dirtyFlag] = new ko.dirtyFlag(data);

         widget.options.viewModel.koDataGrid_dataRows.push(data);

         if (widget.options.highlightFunction)
            $("#" + data.Id()).addClass(widget.options.highlightFunction(data));

         $('.koDataGrid_select_uninitialized').multiselect(widget._buildMultiSelectOptions());
         $('.koDataGrid_select_uninitialized').removeClass('koDataGrid_select_uninitialized');
         $('[data-toggle="tooltip"]').tooltip();
      },

      _buildTable: function (containerElement, columns, settings) {
         var widget = this;
         var headerTable, dataTable, scrollWrapper, headWrapper;
         var tableHtml = '<table class="results"/>';
         var headWrapperHtml = '<div id=\"koDataGrid_headWrapper\" style=\"margin: 0; overflow: hidden;"/>';
         var scrollWrapperHtml = '<div id=\"koDataGrid_scrollWrapper\", style=\"margin: 0; overflow: auto;"/>';

         headWrapper = $(headWrapperHtml).appendTo(containerElement);
         headerTable = $(tableHtml).attr('id', widget._options.tableHead).appendTo(headWrapper);
         scrollWrapper = $(scrollWrapperHtml).appendTo(containerElement);
         dataTable = $(tableHtml).attr('id', widget._options.tableBody).appendTo(scrollWrapper);

         if (settings.maxHeight)
            scrollWrapper.css('max-height', settings.maxHeight);

         widget._buildTHead(headerTable, columns);
         widget._buildTBody(dataTable, columns, settings.id);
         
         $(scrollWrapper).scroll(function () {
            widget._syncHeaderScrollWithTable();
         });

         widget._ie9Hacks();
      },

      _ie9Hacks: function () {
         var widget = this;
         widget.element.find(widget._options.scrollWrapper).css('min-height', '0%');
      },

      _syncHeaderScrollWithTable: function () {
         var widget = this;
         widget.element.find(widget._options.headWrapper).scrollLeft(widget.element.find(widget._options.scrollWrapper).scrollLeft());
      },

      _buildTHead: function (tableElement, columns) {
         var widget = this;
         var thead = $('<thead/>').appendTo(tableElement);
         var headerRow = $('<tr/>').appendTo(thead);
         $.each(columns, function (index, column) {
            var col = $('<th data-bind="visible: koDataGrid_columns()[' + index + '].visible" class="' + widget._makeCSSClass('header', index) + '">' + column.label + '</th>').appendTo(headerRow);
            if (column.alignment)
               col.css('text-align', column.alignment);
            if (column.odataName)
               col.attr('odataName', column.odataName);
            if (!column.odataName || column.nosort)
               col.addClass('nosort');
         });
      },

      _buildTBody: function (tableElement, columns, id) {
         var widget = this;
         var tbody = $('<tbody/>').appendTo(tableElement);
         tbody.append('<!-- ko foreach: koDataGrid_dataRows -->');

         var cssDataBind = widget._options.dirtyClass + ': ' + widget._options.dirtyFlag + '.isDirty';
         if (widget.options.highlightDirty)
            cssDataBind += ', highlightDirty: ' + widget._options.dirtyFlag + '.isDirty';

         var databind = 'attr: { id: ' + id + ' }, css: { ' + cssDataBind + ' }';

         var tableRow = $('<tr data-bind="' + databind + '"/>').appendTo(tbody);

         if (widget.options.nohover)
            tableRow.addClass(widget._options.nohoverClass);
         $.each(columns, function (index, column) {
            var col = $('<td class="' + widget._makeCSSClass('column', index) + '"/>').appendTo(tableRow);
            var element;

            if (column.alignment && column.alignment != 'left')
               col.css('text-align', column.alignment);

            if (column.odataName) {
               element = widget._buildText(col, column, index);
               widget._applyCommonStyling(col, element, column);
            }

            if (column.contents)
               $.each(column.contents, function (i, content) {
                  switch (content.type) {
                     case 'checkbox':
                        element = widget._buildCheckbox(col, content);
                        break;
                     case 'link':
                        element = widget._buildLink(col, content);
                        break;
                     case 'radio':
                        element = widget._buildRadioButton(col, content);
                        break;
                     case 'select':
                        element = widget._buildSelectList(col, content);
                        break;
                     case 'groupedSelect':
                        element = widget._buildGroupedSelectList(col, content);
                        break;
                  }
                  widget._applyCommonStyling(col, element, content);
               });
         });
         tbody.append('<!-- /ko -->');
         tbody.append('<tr id="koDataGrid_loadingRow" class="nohover" data-bind="ensureVisible: koDataGrid_loadingData"><td style="text-align: center;" colspan="' + widget.element.find('thead tr:first th').length + '"><div class="loading" style="display: inline-block"/><b>' + widget.options.loadingMessage + '</b></td></tr>');

         tbody.append('<tr id="koDataGrid_errorRow" class="nohover" data-bind="ensureVisible: koDataGrid_loadingError"><td class="attention" style="text-align: center;" colspan="' + widget.element.find('thead tr:first th').length + '">' + widget.options.errorMessage + ' <a id="koDataGrid_retryLoad">Retry</a></td></tr>');
         widget.element.find('#koDataGrid_retryLoad').click(function (e) {
            widget._load();
         });

         tbody.append('<tr id="koDataGrid_noDataRow" class="nohover" data-bind="ensureVisible: koDataGrid_dataRows().length == 0 && !(koDataGrid_loadingData() || koDataGrid_loadingError())"><td class="italic" style="text-align: center;" colspan="' + widget.element.find('thead tr:first th').length + '">' + widget.options.noDataMessage + '</td></tr>');
      },

      _applyCommonStyling: function (col, element, column) {
         if (column.classes)
            this._addClasses(element, column.classes);
         if (column.helpText)
            $('<i class="fa fa-question-circle superscript" data-toggle="tooltip" data-template="<div class=&quot;tooltip&quot;><div class=&quot;tooltip-arrow&quot;></div><div class=&quot;tooltip-inner long&quot;></div></div>" data-placement="right" data-bind="attr: { title: $parent.getODataValue($data, \'' + column.helpText + '\') }" title="test"></i>').appendTo(col);
      },

      _buildLink: function (col, column) {
         var link = $('<a href="#"/>').appendTo(col);
         link.attr('data-bind', column.databind);
         link.text(column.text);

         if (column.value)
            link.attr('href', column.value);

         return link;
      },

      _buildCheckbox: function (col, column) {
         var checkbox = $('<input type="checkbox"/>').appendTo(col);
         checkbox.attr('value', column.value);

         var databind = "checked: $parent.getODataValue($data, '" + column.checked + "')";
         if (column.databind)
            databind += ', ' + column.databind;

         checkbox.attr('data-bind', databind);
         return checkbox;
      },

      _buildSelectList: function (col, column) {
         var select = $('<select class="koDataGrid_select koDataGrid_select_uninitialized" multiple="multiple"/>').appendTo(col);

         var databind = "options: $parent." + column.options + ", selectedOptions: " + column.selectedOptions;

         if (column.optionsText)
            databind += ", optionsText: '" + column.optionsText + "'";

         if (column.optionsValue)
            databind += ", optionsValue: '" + column.optionsValue + "'";

         if (column.databind)
            databind += ', ' + column.databind;
            
         select.attr('data-bind', databind);
         return select;
      },

      _buildGroupedSelectList: function (col, column) {
         var select = $('<select class="koDataGrid_select koDataGrid_select_uninitialized" multiple="multiple"/>').appendTo(col);
         var selectDatabind = "foreach: $parent." + column.foreach;
         if (column.selectedOptions)
            selectDatabind += ", selectedOptions: " + column.selectedOptions;
         if (column.databind)
            selectDatabind += ', ' + column.databind;
         select.attr('data-bind', selectDatabind);

         var group = $('<optgroup />').appendTo(select);
         var groupDatabind = "attr: {label: " + column.groupName + "}, foreach: " + column.options;
         group.attr('data-bind', groupDatabind);

         var option = $('<option />').appendTo(group);
         var optionDatabind = "";
         if (column.optionsValue)
            optionDatabind += "value: " + column.optionsValue;
         else
            optionDatabind += "value: $data";

         if (column.optionsText)
            optionDatabind += ", text: " + column.optionsValue;
         else if (column.optionsValue)
            optionDatabind += ", text: " + column.optionsValue;
         else
            optionDatabind += ", text: $data";

         if (column.enable)
            optionDatabind += ", enableDefaultOff: " + column.enable;
         option.attr('data-bind', optionDatabind);

         return select;
      },

      _buildRadioButton: function (col, column) {
         var radio = $('<input type="radio"/>').appendTo(col);
         radio.attr('value', column.value);

         var databind = "attr: {name: '" + column.checked + "' + $index(), id: '" + column.value + "' + $index() }, checked: $parent.getODataValue($data, '" + column.checked + "')";
         if (column.databind)
            databind += ', ' + column.databind;
         radio.attr('data-bind', databind);
         $('<label data-bind="attr: {\'for\': \'' + column.value + '\' + $index() }" />').text(column.text).appendTo(col);
         if (column.lineBreak)
            $('<br/>').appendTo(col);
         return radio;
      },

      _buildText: function (col, column, index) {
         var binding;
         var text = $('<span/>').appendTo(col);
         if (!column.databind) {
            var defaultBinding;
            if (column.transform)
               defaultBinding = "$parent.koDataGrid_columns()[" + index + "].transform($root.unwrapData($data))";
            else
               defaultBinding = "$parent.getODataValue($data, '" + column.odataName + "')";
            if (column.html)
               binding = "html: " + defaultBinding;
            else
               binding = "text: " + defaultBinding;
         } else {
            binding = column.databind;
         }

         binding += ", visible: $parent.koDataGrid_columns()[" + index + "].visible";

         text.attr('data-bind', binding);
         if (column.wrapText) {
            text.css("white-space", "pre");
         }
         return text;
      },

      _addClasses: function (element, classes) {
         if (classes)
            $.each(classes, function (i, className) {
               element.addClass(className);
            });
      },

      _options: {
         rootClass: 'koDataGrid_base',

         scrollWrapper: '#koDataGrid_scrollWrapper',
         headWrapper: '#koDataGrid_headWrapper',
         selectedRow: 'tr.selected',
         loadingRow: '#koDataGrid_loadingRow',
         errorRow: '#koDataGrid_errorRow',
         tableHead: 'koDataGrid_head',
         tableBody: 'koDataGrid_body',

         resetEvent: 'reset',
         rowClickedEvent: 'rowClicked',
         loadingEvent: 'loading',
         loadSuccessEvent: 'success',
         loadErrorEvent: 'error',

         selectedClass: 'selected',
         nohoverClass: 'nohover',
         dirtyClass: 'dirty',

         dirtyFlag: 'koDataGrid_dirtyFlag'
      },

      _buildMultiSelectOptions: function() {
         var widget = this;

         return {
            numberDisplayed: 1,
            buttonContainer: '<div class="btn-group koDataGrid_selectButton" />', 
            /*
            Dirty hack. There isn't an event exposed for after these events happen, and we need to resize after the
            layout changes, not before. Since js is single threaded, waiting for 1ms will let whatever was going to happen
            to happen, then once it finishes, it comes back and executes the resize.
            */
            onChange: function() {
               setTimeout($.proxy(widget.updateSize, widget), 1);
            },
            onDropdownShow: function (event) {
               var target = event.target;
               $(target).find(".multiselect-container").hide();
               setTimeout(function() {
                  $.proxy(widget.updateSize, widget);
                  widget._positionMultiSelectDropdown(target);
                  $(target).find(".multiselect-container").show();
               }, 1);
            },
            onDropdownHide: function (event) {
               var target = event.target;
               $(target).find(".multiselect-container").hide();
               setTimeout($.proxy(widget.updateSize, widget), 1);
            }
         }
      },

      _positionMultiSelectDropdown: function (multiselect) {
         var widget = this;
         var scrollWrapper = $(widget._options.scrollWrapper);
         var container = $(multiselect).find(".multiselect-container");
         var boundingRectangle = multiselect.getBoundingClientRect();
         var top = boundingRectangle.top + $(multiselect).height() - 5;
         var left = Math.min(boundingRectangle.left, $(window).width() - container.width());

         var scrollWrapperRectangle = scrollWrapper.get(0).getBoundingClientRect();
         if (boundingRectangle.bottom > scrollWrapperRectangle.bottom || boundingRectangle.bottom < scrollWrapperRectangle.top) {
            container.hide();
            return;
         }
         container.show();
         container.css({ top: top, left: left });
      },

      _positionOpenMultiSelectDropdown: function () {
         var widget = this;
         $('.koDataGrid_selectButton.open').each(function (i, element) { widget._positionMultiSelectDropdown(element); });
      }
   });

   ko.bindingHandlers.ensureVisible = {
      update: function (element, valueAccessor) {
         ko.bindingHandlers.visible.update(element, valueAccessor);

         var isVisible = ko.utils.unwrapObservable(valueAccessor());
         if (isVisible)
            Softek.ensureElementVisible($(element).offsetParent(), element);
      }
   };

   ko.bindingHandlers.enableDefaultOff = {
      update: function (element, valueAccessor) {
         ko.bindingHandlers.enable.update(element, valueAccessor);

         var isEnabled = ko.utils.unwrapObservable(valueAccessor());
         if (!isEnabled)
            $(element).removeAttr('checked');
      }
   };

   ko.dirtyFlag = function (root, isInitiallyDirty) {
      var result = function () { },
        _initialState = ko.observable(ko.mapping.toJSON(root)),
        _isInitiallyDirty = ko.observable(isInitiallyDirty);

      result.isDirty = ko.computed(function () {
         return _isInitiallyDirty() || _initialState() !== ko.mapping.toJSON(root);
      });

      result.commit = function () {
         _initialState(ko.mapping.toJSON(root));
         _isInitiallyDirty(false);
      };

      result.undo = function () {
         if (!result.isDirty()) {
            return;
         }

         ko.mapping.fromJSON(_initialState(), {}, root);
         _isInitiallyDirty(false);
      };

      return result;
   };
})(jQuery);