﻿//Adds additional input masks to make jquery ui cultures translate into date/time input masks.
(function (inputmask) {
    inputmask.extendDefinitions({
      'H': { //hours 1-12
         validator: "0[1-9]|1[0-2]",
         cardinality: 2,
         prevalidator: [{ validator: "[0-1]", cardinality: 1 }]
      },
      'p': {//period am/pm
         validator: "[ap]m",
         cardinality: 2,
         prevalidator: [{ validator: "[ap]", cardinality: 1 }]
      }
   });
    inputmask.extendAliases({
      'mm/dd/yyyy h:mm:ss tt': {
         mask: "1/2/y H:s:s p",
         placeholder: "mm/dd/yyyy hh:mm:ss _m ",
         leapday: "02/29",
         alias: "mm/dd/yyyy"
      },
      'dd/mm/yyyy h:mm:ss tt': {
         mask: "1/2/y H:s:s p",
         placeholder: "dd/mm/yyyy hh:mm:ss _m ",
         leapday: "29/02",
         alias: "dd/mm/yyyy"
      },
      'dd/mm/yyyy HH:mm:ss': {
         mask: "1/2/y h:s:s",
         placeholder: "dd/mm/yyyy hh:mm:ss",
         leapday: "29/02",
         alias: "dd/mm/yyyy"
      },
      'm/d/yyyy h:mm:ss tt': {
         alias: "mm/dd/yyyy h:mm:ss tt"
      },
      'm/d/yyyy': {
         alias: "mm/dd/yyyy"
      }
   });
})(Inputmask);;