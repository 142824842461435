﻿$.validator.addMethod("softek_requiredifnotequal", function (value, element, parameters) {
   var firstField = $(element).data('val-requiredifnotequal-firstproperty');
   var secondField = $(element).data('val-requiredifnotequal-secondproperty');
   
   var firstElement = $('#' + firstField).length ? $('#' + firstField) : $('#' + element.id.substr(0, element.id.lastIndexOf('_')) + "_" + firstField);
   var secondElement = $('#' + secondField).length ? $('#' + secondField) : $('#' + element.id.substr(0, element.id.lastIndexOf('_')) + "_" + secondField);

   if (firstElement.val() !== secondElement.val() && !value)
      return false;
   return true;
});

$.validator.unobtrusive.adapters.addBool('requiredifnotequal', 'softek_requiredifnotequal');