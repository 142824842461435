﻿$(document).ready(function () {
   // Place qtips above elements on the page, but below the header and modal dialogs
   $.fn.qtip.zindex = 3;

   $('div.sidebar').find('form').each(function (index) {
      var validator = $.data($(this)[0], 'validator');
      if (!validator)
         return;
      var validatorSettings = validator.settings;
      validatorSettings.success = $.noop;
      validatorSettings.errorPlacement = function (error, element) {
         // Set positioning based on the elements position in the form
         var elem = $(element),
                            corners = ['left center', 'right center'],
                            flipIt = elem.parents('span.right').length > 0;

         // Check we have a valid error message
         if (!error.is(':empty')) {
            error.removeClass("input-validation-error");
            // Apply the tooltip only if it isn't valid
            AddQtip(elem, error)

            // If we have a tooltip on this element already, just update its content
                              .qtip('option', 'content.text', error);
         }
            // If the error is empty, remove the qTip
         else {
            elem.qtip('destroy');
         }
      };
   });

   $('div.sidebar .field-validation-error').each(function () {
      // Get the name of the element the error message is intended for
      // Note: ASP.NET MVC replaces the '[', ']', and '.' characters with an
      // underscore but the data-valmsg-for value will have the original characters
      var inputElem = '#' + $(this).attr('data-valmsg-for').replace('.', '_').replace('[', '_').replace(']', '_');

      // Hide the default validation error
      $(this).hide();

      // Show the validation error using qTip
      AddQtip($(inputElem), $(this).text());
   });

   function AddQtip(element, error) {
      var corners = ['left center', 'right center'];
      var flipIt = $(element).parents('span.right').length > 0;
      
      return element.filter(':not(.valid)').qtip({
         overwrite: false,
         content: error,
         position: {
            my: corners[flipIt ? 0 : 1],
            at: corners[flipIt ? 1 : 0],
            viewport: $(window)
         },
         show: {
            event: false,
            ready: true
         },
         hide: false,
         style: {
            classes: 'qtip-red qtip-shadow qtip-rounded' // Make it red... the classic error colour!
         }
      });
   }
});