﻿/// <reference path="C:\Code\OnTrackDiscoveryPortal\Softek.Service.SystemAudit.Web\Views/ROIReports/Index.cshtml" />
$(function () {
    $(document).on('click', ".expandable-down,.expandable-up", function () {
        var current = $(this);
        current.next().slideToggle('slow', function () {
            current.toggleClass("expandable-down expandable-up");
        });
        return false;
    });
});

(function (w) {

    w.Softek = {
        formatNonUtcDate: function (date) {
            var dateObject = Date.parse(date);
            return dateObject;
        },

        formatShortDate: function (date) {
            var dateObject = formatNonUtcDate(date);
            return Globalize.format(dateObject, 'd');
        },

        getDateOneWeekBack: function () {
            return Globalize.format(new Date(new Date().setDate(new Date().getDate() - 7)), 'd');
        },

        ParseUtcDate: function (value) {
            var dateObject = new Date(value);
            dateObject.addMinutes(-dateObject.getTimezoneOffset());
            return dateObject;
        },

        formatUTCDate: function (date) {
            var dateObject = Date.parse(date);
            dateObject.addMinutes(dateObject.getTimezoneOffset());
            return Globalize.format(dateObject, 'S');
        },

        formatClientDate: function (date) {
            var dateObject = Softek.ParseUtcDate(date);
            return Globalize.format(dateObject, 'd') + ' ' + Globalize.format(dateObject, 'T');
        },

        formatDate: function (date) {
            var dateObject = Date.parse(date);
            return Globalize.format(dateObject, 'd') + ' ' + Globalize.format(dateObject, 'T');
        },

        timeToClientTime: function (timeObject) {
            var dateObject = Date.parse(timeObject.TimeString);
            dateObject.addMinutes(dateObject.getTimezoneOffset());
            return Globalize.format(dateObject, 'T');
        },

        formatAuditParameter: function (row, typeName, value) {
            var formatFunctions = {
                "DateTime": function () { return Softek.formatClientDate(value); },
                "Time": function () { return Softek.timeToClientTime(value); },
                "List`1": function () { return value.join(", "); },
                "String": function () { return value === null ? "N/A" : value; }
            };

            var formatFunction = formatFunctions[typeName];
            if (typeof formatFunction !== 'function')
                return value;
            return formatFunction();
        },

        showUnauthorizedNotification: function () {
            $.gritter.add({
                title: 'Error',
                text: 'Your authentication has expired. Please refresh the page to continue.',
                time: '5000'
            });
        },

        ensureElementVisible: function (viewPort, element) {
            element = $(element);
            viewPort = $(viewPort);

            var scrollBarWidth = 0;
            if (element.outerWidth(true) > viewPort.outerWidth(true))
                scrollBarWidth = Softek.getScrollBarWidth();

            if (!element.length)
                return;
            var inViewport = Softek.elementInViewport(viewPort, element, scrollBarWidth);

            if (inViewport == -1) { //it's above the table
                viewPort.scrollTo(element, 0, { offset: { top: 0 }, axis: 'y' });
            } else if (inViewport == 1) { //it's below the table
                viewPort.scrollTo(element,
                    0,
                    { offset: { top: -viewPort.height() + element.height() + scrollBarWidth }, axis: 'y' });
            }
        },

        elementInViewport: function (viewPort, element, bottomOffset) {
            var tableTop = viewPort.offset().top;
            var tableBottom = tableTop + viewPort.height() - bottomOffset;

            var elementTop = element.offset().top;
            var elementBottom = elementTop + element.height();

            if (elementBottom > tableBottom)
                return 1;
            else if (elementTop < tableTop)
                return -1;
            else
                return 0;
        },

        getScrollBarWidth: function () {
            var inner = document.createElement('p');
            inner.style.width = "100%";
            inner.style.height = "200px";

            var outer = document.createElement('div');
            outer.style.position = "absolute";
            outer.style.top = "0px";
            outer.style.left = "0px";
            outer.style.visibility = "hidden";
            outer.style.width = "200px";
            outer.style.height = "150px";
            outer.style.overflow = "hidden";
            outer.appendChild(inner);

            document.body.appendChild(outer);
            var w1 = inner.offsetWidth;
            outer.style.overflow = 'scroll';
            var w2 = inner.offsetWidth;
            if (w1 == w2) w2 = outer.clientWidth;

            document.body.removeChild(outer);

            return (w1 - w2);
        },

        getMaxHeightFunction: function (gridElement) {

            return function () {
                var footerHeight = $('div.footer').height();
                var headerHeight = 157;
                return Math.max($(window).height() -
                    headerHeight -
                    footerHeight -
                    ($('div.content').height() - gridElement.height()),
                    $('div.sidebar').height() - footerHeight - ($('div.content').height() - gridElement.height()));
            };
        },

        getProportionalMaxHeightFunction: function (selectedElement, gridElements) {
            var sumGridElementsHeight = function (elements) {
                var height = 0;
                $(elements).each(function () {
                    height += $(this.element).outerHeight(true);
                });
                return height;
            }

            var totalShares = 0;
            $(gridElements).each(function (i, element) {
                totalShares += element.shares;
            });

            return function () {
                var footerHeight = $('div.footer').height();
                var headerHeight = 157;
                var heightInShare = (Math.max($(window).height() - headerHeight - footerHeight - ($('div.content').height() - sumGridElementsHeight(gridElements)),
                    $('div.sidebar').height() - footerHeight - ($('div.content').height() - sumGridElementsHeight(gridElements))) / totalShares);

                //This currently will try to reallocate unused space. It only makes one pass, so with how it's written, if one of the 
                //elements recieving extra space doesn't need it's full share of extra space, there will still be gaps. However,
                //this behavior should not appear in a two table layout, which is what this is being used for. So, this is being left until
                //that is actually an issue.
                var spareHeight = 0;
                var elementsWithSpareSpace = 0;
                $(gridElements).each(function (i, element) {
                    var currentHeight = Softek.GetGridExpandedHeight(element);
                    var allowedHeight = heightInShare * element.shares;
                    if (currentHeight < allowedHeight) {
                        spareHeight += allowedHeight - currentHeight;
                        elementsWithSpareSpace++;
                    }
                });

                var allocatedHeight = 0;
                $(gridElements).each(function (i, element) {
                    if (selectedElement.get(0) === element.element.get(0)) {
                        allocatedHeight = heightInShare * element.shares;
                        var currentHeight = Softek.GetGridExpandedHeight(element);
                        if (currentHeight < allocatedHeight) {
                            allocatedHeight = currentHeight;
                        } else if (elementsWithSpareSpace > 0)
                            allocatedHeight += spareHeight / (gridElements.length - elementsWithSpareSpace);
                    }
                });
                return allocatedHeight;
            };
        },

        GetGridExpandedHeight: function (element) {
            var height = element.element.find("#koDataGrid_body").height() + element.element.find('#koDataGrid_head').height();
            if (element.element.find("#koDataGrid_body").width() > element.element.find("#koDataGrid_scrollWrapper").width())
                height += Softek.getScrollBarWidth();
            return height;
        },

        EscapeODataQueryValue: function (input) {
            return encodeURIComponent(input.replace(/'/g, "''"));
        },

        ResolveFormFieldValue: function (formElement, name) {
            var element = $('#' + name);
            if (!element.length) {
                name = formElement.id.substr(0, formElement.id.lastIndexOf('_')) + "_" + name;
                element = $('#' + name);
            }
            var type = element.attr('type');
            if (type === 'radio' || type === 'checkbox')
                element = $('input[name=' + name + ']:checked');
            return element.val();
        },

        optionPair: function (id, description) {
            this.id = id;
            this.description = description;
        },

        FixMnemonicInput: function (input) {
            var cursorStart = input.selectionStart;
            var cursorEnd = input.selectionEnd;

            var fixedMnemonic = input.value.toUpperCase();

            var re = new RegExp('-', 'g');
            fixedMnemonic = fixedMnemonic.replace(re, '_');

            if (input.value != fixedMnemonic) {
                input.value = fixedMnemonic;
                input.setSelectionRange(cursorStart, cursorEnd);
            }
        }
    };
})(window);


$.views.tags("clientDate", function (val) {
    return Softek.formatClientDate(val);
});

$.views.tags("auditParameter", function (val) {
    return Softek.formatAuditParameter(val, val.TypeName, val.ObjectValue);
});


$(document).ready(function () {
    var storedTimeZoneOffset = $.cookie('TimeZoneOffset'),
        currentTimeZoneOffset = jstz.determine().name();
    if (storedTimeZoneOffset === null || storedTimeZoneOffset !== currentTimeZoneOffset) {
        $.cookie('TimeZoneOffset', currentTimeZoneOffset, { secure: true });
        document.location.reload(true);
    }
});

//http://stackoverflow.com/questions/2830542/prevent-double-submission-of-forms-in-jquery
// jQuery plugin to prevent double submission of forms
$(function () {
    $(document).on('submit', "form", function (event) {
        var $form = $(this);

        if ($form.data('submitted') === true) {
            // Previously submitted - don't submit again
            event.preventDefault();
        } else {
            // Mark it so that the next submit can be ignored
            $form.data('submitted', true);
        }
    });

    // Keep chainability
    return this;
});


//From http://stackoverflow.com/questions/5457393/reset-mvc-form-with-jquery
//Plugin to reset validation on a form.
(function ($) {

    //re-set all client validation given a jQuery selected form or child
    $.fn.resetValidation = function () {

        var $form = this.closest('form');

        //reset jQuery Validate's internals
        $form.validate().resetForm();

        //reset unobtrusive validation summary, if it exists
        $form.find("[data-valmsg-summary=true]")
            .removeClass("validation-summary-errors")
            .addClass("validation-summary-valid")
            .find("ul").empty();

        //reset unobtrusive field level, if it exists
        $form.find("[data-valmsg-replace]")
            .removeClass("field-validation-error")
            .addClass("field-validation-valid")
            .empty();

        return $form;
    };
})(jQuery);

// From http://stackoverflow.com/questions/610406/javascript-equivalent-to-printf-string-format
if (!String.prototype.format) {
    String.prototype.format = function () {
        var args = arguments;
        return this.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined'
                ? args[number]
                : match
                ;
        });
    };
}

ko.bindingHandlers.slideIn = {
    init: function (element, valueAccessor) {
        var value = ko.utils.unwrapObservable(valueAccessor());
        $(element).toggle(value);
    },
    update: function (element, valueAccessor) {
        var value = ko.utils.unwrapObservable(valueAccessor());
        value ? $(element).slideDown() : $(element).slideUp();
    }
};