﻿
jQuery(function ($) {
   $.timepicker.regional['en-CA'] = {
      currentText: 'Now',
      closeText: 'Done',
      ampm: true,
      amNames: ['AM', 'A'],
      pmNames: ['PM', 'P'],
      timeFormat: 'hh:mm:ss tt',
      timeSuffix: '',
      timeOnlyTitle: 'Choose Time',
      timeText: 'Time',
      hourText: 'Hour',
      minuteText: 'Minute',
      secondText: 'Second',
      millisecText: 'Millisecond',
      timezoneText: 'Time Zone'
   };
});

jQuery(function ($) {
   $.timepicker.regional['en-GB'] = {
      currentText: 'Now',
      closeText: 'Done',
      ampm: false,
      amNames: ['AM', 'A'],
      pmNames: ['PM', 'P'],
      timeFormat: 'hh:mm:ss',
      timeSuffix: '',
      timeOnlyTitle: 'Choose Time',
      timeText: 'Time',
      hourText: 'Hour',
      minuteText: 'Minute',
      secondText: 'Second',
      millisecText: 'Millisecond',
      timezoneText: 'Time Zone'
   };
});

jQuery(function ($) {
   $.timepicker.regional['en-US'] = {
      currentText: 'Now',
      closeText: 'Done',
      ampm: true,
      amNames: ['AM', 'A'],
      pmNames: ['PM', 'P'],
      timeFormat: 'hh:mm:ss tt',
      timeSuffix: '',
      timeOnlyTitle: 'Choose Time',
      timeText: 'Time',
      hourText: 'Hour',
      minuteText: 'Minute',
      secondText: 'Second',
      millisecText: 'Millisecond',
      timezoneText: 'Time Zone'
   };
});