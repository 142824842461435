﻿$.validator.addMethod("softek_minlengthifequal", function (value, element, parameters) {
   var firstField = $(element).data('val-minlengthifequal-firstproperty');
   var secondField = $(element).data('val-minlengthifequal-secondproperty');
   var objectValue = $(element).data('val-minlengthifequal-value');
   var minLength = $(element).data('val-minlengthifequal-minlength');
   
   var firstValue = Softek.ResolveFormFieldValue(element, firstField);
   if (secondField)
      objectValue = Softek.ResolveFormFieldValue(element, secondField);

   if (firstValue === objectValue && value.length < minLength)
      return false;
   return true;
});

$.validator.unobtrusive.adapters.addBool('minlengthifequal', 'softek_minlengthifequal');