﻿$.validator.addMethod("softek_requiredifequal", function (value, element, parameters) {
   var firstField = $(element).data('val-requiredifequal-firstproperty');
   var secondField = $(element).data('val-requiredifequal-secondproperty');
   var objectValue = $(element).data('val-requiredifequal-value');

   var firstValue = Softek.ResolveFormFieldValue(element, firstField);
   if (secondField)
      objectValue = Softek.ResolveFormFieldValue(element, secondField);

   if (firstValue === objectValue && !value)
      return false;
   return true;
});

$.validator.unobtrusive.adapters.addBool('requiredifequal', 'softek_requiredifequal');