﻿$.validator.addMethod("softek_notequal", function (value, element) {
   var fieldCount = $(element).data('val-notequal-fieldcount');
   for (var i = 1; i <= fieldCount; i++) {
      var fieldName = $(element).data('val-notequal-validationfield' + i);
      var comparisonElement = $('#' + fieldName).length ? $('#' + fieldName)
                                                        : $('#' + element.id.substr(0,
                                                                     element.id.lastIndexOf('_')) + "_" + fieldName);
      if (!comparisonElement)
         continue;
      if (comparisonElement.val() === value) {
         if ($(element).is('select:focus') &&
             !$('span[for="' + $(element).attr('id') + '"]').parent().hasClass('field-validation-error'))
            return true;
         return false;
      }
   }
   return true;
});

$.validator.unobtrusive.adapters.addBool('notequal', 'softek_notequal');