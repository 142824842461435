﻿(
function ($) {
   var style_styleSheetId = "style_dynamicStyleSheet";

   $.cssStyle = {
      insertRule: function (selector, rules) {
         var context = document, stylesheet;

         if (typeof context.styleSheets == 'object') {
            if (!$(context).find('#' + style_styleSheetId).length)
               $(context).find('head').append('<style id="' + style_styleSheetId + '" title="' + style_styleSheetId + '" rel="stylesheet" type="text/css"></style>');

            stylesheet = getDynamicStyleSheet(context);
            if (typeof (stylesheet) === "undefined")
               return;

            if (stylesheet.addRule) {
               for (var i = 0; i < selector.length; ++i) {
                  stylesheet.addRule(selector[i], rules, 0);
               }
            }
            else {
               stylesheet.insertRule(selector.join(',') + '{' + rules + '}', stylesheet.cssRules.length, 0);
            }
         }
      },

      removeClass: function (selector) {
         var context = document, stylesheet;

         if (typeof context.styleSheets == 'object') {
            stylesheet = getDynamicStyleSheet(context);
            if (typeof (stylesheet) === "undefined")
               return;

            for (var i = 0; i < selector.length; ++i) {
               var index = findCSSRule(stylesheet, selector[i]);
               if (index != -1) {
                  if (stylesheet.removeRule)
                     stylesheet.removeRule(index);
                  else
                     stylesheet.deleteRule(index);
               }
            }
         }
      }
   };

   function findCSSRule(stylesheet, selector) {
      var ruleIndex = -1;
      var rules = stylesheet.cssRules ? stylesheet.cssRules : stylesheet.rules;
      if (rules == null)
         return ruleIndex;
      for (i = 0; i < rules.length; i++) {
         if (rules[i].selectorText == selector) {
            ruleIndex = i;
            break;
         }
      }
      return ruleIndex;
   }

   function getDynamicStyleSheet(context) {
      for (var i = 1; i <= context.styleSheets.length; i++) {
         if (context.styleSheets[context.styleSheets.length - i].title === style_styleSheetId) {
            return context.styleSheets[context.styleSheets.length - i];
         }
      }
      return undefined;
   }
}
)(jQuery);
