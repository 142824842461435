﻿$.validator.addMethod("softek_notequalifnotempty", function (value, element) {
   if (value === "")
      return true;
   var fieldCount = $(element).data('val-notequalifnotempty-fieldcount');
   for (var i = 1; i <= fieldCount; i++) {
      var fieldName = $(element).data('val-notequalifnotempty-validationfield' + i);
      var comparisonElement = $('#' + fieldName).length ? $('#' + fieldName) : $('#' + element.id.substr(0, element.id.lastIndexOf('_')) + "_" + fieldName);
      if (!comparisonElement)
         continue;
      if (comparisonElement.val() === value)
         return false;
   }
   return true;
});

$.validator.unobtrusive.adapters.addBool('notequalifnotempty', 'softek_notequalifnotempty');